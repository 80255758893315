import React, { Component, Fragment } from 'react';

import { graphql, compose } from 'react-apollo';
import gql from "graphql-tag";

import cx from "classnames";
import './styles.css';

import { Button, Classes } from "@blueprintjs/core";

import { withSocket } from '../../../utils/socketio';
import { Messages, ChatMessage, NotificationMessage, NotificationError, Typing } from './messages';

import { Router, Location } from "@reach/router";

import Shared from '../../../shared';
import coreTools from '../../index';

import moment from 'moment';

const GET_MESSAGES = gql`
query getMessages($communityId: ID!, $roomId: ID, $beforeID: ID) {
  messages(communityId: $communityId, roomId: $roomId, beforeID: $beforeID) {
    roomId
    communityId
    cursor
    hasMore
    messages {
      id
      createdAt
      message
      custom_data
      user {
        id
        email
      }
      type
    }   
  }
}
`;

class RawCommunication extends Component {

  constructor(props) {
    super(props);

    const { socket } = this.props;
    
    // TODO: melhorar!!
    this.tools = {components: {}};
    Object.keys(Shared.tools).filter(key => key !== 'components').map(key => this.tools[key] = Shared.tools[key]);
    Object.keys(Shared.tools.components).map(key => this.tools.components[key] = Shared.tools.components[key]);
    Object.keys(coreTools).filter(key => key !== 'components').map(key => this.tools[key] = coreTools[key]);
    Object.keys(coreTools.components).map(key => this.tools.components[key] = coreTools.components[key]);

    this.state = {
      username: '',
      message: '',

      connected: true, // keep it true for now
      messages: [],
      typing: [],
    }

    this.onReconnect = this.onReconnect.bind(this);

    socket.on('login', (data) => this.onLogin(data));
    socket.on('new message', (data) => this.addMessageFromSocket(data));
    socket.on('new command', (data) => this.handleCommand(data));
    socket.on('typing', (data) => this.onTyping(data));
    socket.on('stop typing', (data) => this.onStopTyping(data));    
    socket.on('reconnect', this.onReconnect);
  }

  componentDidMount() {

    this.setState({ 'username': localStorage.getItem('username') });
    this.scrollToEnd();

    const { socket, communityId, roomId } = this.props;
    socket.emit('join room', { communityId, roomId });
  }

  componentWillUnmount() {
    const { socket } = this.props;

    socket.emit('leave room');

    socket.removeListener('login');
    socket.removeListener('new message');
    socket.removeListener('new command');
    socket.removeListener('typing');
    socket.removeListener('stop typing');
    socket.removeListener('reconnect', this.onReconnect);
  }
  
  render() {

    const { messages } = this.state;

    const { data: { loading, error } } = this.props;

    const oldMessages =
      this.props.data.messages && this.props.data.messages.messages ?
        this.props.data.messages.messages.map(({ user: { email: username }, message, custom_data, type, createdAt }) => this.addMessageComponent(username, message, type, custom_data, createdAt))
        : [];

    const hasMore = this.props.data.messages && this.props.data.messages.hasMore ?
      this.props.data.messages.hasMore
      : false;

    if (loading) return <div>LOADING...</div>;
    if (error) return <div>ERROR!</div>;

    // console.log('RENDER', this.props.data.messages.messages);

    return <div className="communication">
      <div className="messages">
        {hasMore && <div className="old_messages">
          <Button onClick={this.getMoreMessages} className={cx(Classes.MINIMAL, 'old_messages_button')} icon="chat" text="mensagens anteriores" />
        </div>}
        {!hasMore && <div className="no_old_messages"></div>}


        <Messages messages={oldMessages.concat(messages)} />
        <div className="typing"><Typing data={this.state.typing} /></div>

      </div>
      <div className="message_input">
        <textarea name="message" onFocus={this.handleFocus} onChange={this.handleChange} value={this.state.message} onKeyUp={this.handleKDown} placeholder="Digite aqui..." disabled={!this.state.connected || this.props.connectionFail} />
      </div>
    </div>;
  }

  addMessageFromSocket = (data) => {

    const { messageAdded } = data;
    const { communityId, roomId, result: { client } } = this.props; // TODO: quem é este result e pq ele carrega client??

    let variables = {
      communityId
    }
    if (roomId) {
      variables.roomId = roomId;
    }

    try {
      const data = client.readQuery({
        query: GET_MESSAGES,
        variables
      });

      data.messages.messages.push(messageAdded);
      client.writeQuery({ query: GET_MESSAGES, data });

      this.setState({ workaround: new Date() });

    } catch (e) {
      // Do Nothing
    }
  }

  addMessageComponent = (username, message, type, custom_data, createdAt) => {



    // transform createdAt as time
    const time = moment(createdAt).format('DD/MM/YYYY, HH:mm:ss');

    if (type === 'notification') {
      if (custom_data.component && this.tools && this.tools.components && this.tools.components[custom_data.component]) {

        const MessageComponent = this.tools.components[custom_data.component];

        let MessageActionComponent;
        if (custom_data.actionComponent) MessageActionComponent = this.tools.components[custom_data.actionComponent];

        return <NotificationMessage
          mine={this.state.username === username}
          username={username}
          message={message}
          actionComponent={custom_data.actionComponent && <MessageActionComponent
            data={custom_data.data}
            communityId={this.props.communityId}
            roomId={this.props.roomId ? this.props.roomId : null}
            navigateRelative={this.doRelativeNavigation}
            navigateAbsolute={this.doAbsoluteNavigation}
          />}
        >

          <MessageComponent username={username} message={message} data={custom_data.data} time={time} />
        </NotificationMessage>;

      } else {
        return <NotificationError />;
      }
    } else
      return <ChatMessage mine={this.state.username === username} username={username} message={message} time={time} />
  }

  doAbsoluteNavigation = (url) => {
    const { navigate } = this.props;
    navigate(url);
  }

  doRelativeNavigation = (url) => {
    const { navigate, communityId } = this.props;
    navigate(`/${communityId}/${url}`);
  }

  sendMessage = () => {
    let { message } = this.state;
    const { socket, communityId, roomId } = this.props;

    this.props.mutate({
      variables: { communityId, roomId: (roomId ? roomId : null), message, type: 'chat' },
      update: (proxy, { data: { addMessage, addMessage: { messageAdded } } }) => { /* TODO: error handling when mutation returns error! */

        if(!addMessage.success) return;
        
        socket.emit('new message', { messageAdded });

        let variables = {
          communityId
        }
        if (roomId) {
          variables.roomId = roomId;
        }

        try {
          const data = proxy.readQuery({
            query: GET_MESSAGES,
            variables
          });

          data.messages.messages.push(messageAdded);
          proxy.writeQuery({ query: GET_MESSAGES, data });

          this.setState({ workaround: new Date() });

        } catch (e) {
          // Do Nothing
        }
      },
    })

    this.setState({ message: '' });
  }

  handleKDown = (e) => {
    const { socket } = this.props;

    if (e.keyCode === 13 && !e.shiftKey) {
      this.sendMessage();

      socket.emit('stop typing');
    } else {
      if (this.state.connected) {
        socket.emit('typing');
        if (this.typingTimeout) clearTimeout(this.typingTimeout);
        this.typingTimeout = setTimeout(() => {
          socket.emit('stop typing');
        }, 1000);
      }
    }
  }

  onTyping = (data) => {
    if (!this.state.typing.includes(data.username)) {
      this.setState({
        typing: [...this.state.typing, data.username]
      });
    }
  }
  onStopTyping = (data) => {
    if (this.state.typing.includes(data.username)) {
      this.setState({
        typing: this.state.typing.filter(username => username !== data.username)
      });
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleFocus = (e) => {
    this.scrollToEnd();
  }

  scrollToEnd = () => {
    setTimeout(() => {
      const $el = document.querySelector('.messages');
      $el && ($el.scrollTop = 100000000);
    }, 500);
  }

  onLogin = (data) => {
    this.setState({ connected: true });
  }

  onReconnect() {
    const { socket, communityId, roomId } = this.props;

    console.log('onRECONNECT - Comm');

    socket.emit('join room', { communityId, roomId });
  }

  getMoreMessages = () => {
    const { cursor: beforeID, hasMore } = this.props.data.messages;
    const { communityId, roomId } = this.props;

    if (hasMore && beforeID) {
      this.props.data.fetchMore({
        variables: { communityId, beforeID, roomId: roomId ? roomId : null },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;

          const { hasMore, cursor, messages: previousMessages } = fetchMoreResult.messages;

          return {
            messages: {
              ...prev.messages,
              hasMore,
              cursor,
              messages: [...previousMessages, ...prev.messages.messages]
            }
          };
        }
      });
    }

  }

  handleCommand = (data) => {
    if (this.props.onCommand) this.props.onCommand(data);
  }

}

const WithLocationCommunication = props => (
  <Location>
    {({ navigate }) => (
      <RawCommunication {...props} navigate={navigate} />
    )}
  </Location>
);

export default compose(
  graphql(GET_MESSAGES, {
    options: (props) => ({
      fetchPolicy: 'cache-and-network',
      communityId: props.communityId,
      roomId: props.roomId ? props.roomId : null
    }),
  }),
  graphql(gql`
      mutation addMessage($communityId: ID!, $roomId: ID, $message: String!, $type: String!){
        addMessage(communityId: $communityId, roomId: $roomId, message: $message, type: $type) {
          success
          message
          messageAdded {
            id
            createdAt
            message
            custom_data
            user {
              id
              email
            }
            type
          }
        }
      }
    `),
)(withSocket(WithLocationCommunication));