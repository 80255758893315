import React, { Component } from 'react';
import { withSocket } from '../../../utils/socketio';
import { withApollo } from 'react-apollo';

import gql from "graphql-tag";

const ADD_MESSAGE = gql`
    mutation addMessage($communityId: ID!, $roomId: ID, $message: String!, $custom_data: JSON, $type: String!){
        addMessage(communityId: $communityId, roomId: $roomId, message: $message, custom_data: $custom_data type: $type) {
            success
            message
            messageAdded {
                id
                createdAt
                message
                custom_data
                user {
                    id
                    email
                }
                type
            }
        }
    }
`;

const GET_MESSAGES = gql`
    query getMessages($communityId: ID!, $roomId: ID) {
        messages(communityId: $communityId, roomId: $roomId, beforeID: $beforeID) {
            roomId
            communityId
            cursor
            hasMore
            messages {
                id
                createdAt
                message
                custom_data
                user {
                    id
                    email
                }
                type
            }   
        }
    }
`

export const withCommunication = (WrappedComponent) => {
    return withSocket(withApollo(class extends Component {

        render() {
            return <WrappedComponent {...this.props} sendNotification={this.sendNotification} sendCommand={this.sendCommand} />;
        }

        sendCommand = (communityId, roomId, custom_data) => {
            const { socket } = this.props;
            
            socket.emit('new command', { custom_data, communityId, roomId });
        }

        sendNotification = async (communityId, roomId, message, custom_data) => {
            const { socket } = this.props;            

            await this.props.client.mutate({
                mutation: ADD_MESSAGE,
                variables: {
                    communityId,
                    roomId: (roomId !== 'general') ? roomId : null,
                    message,
                    custom_data,
                    type: 'notification',
                },
                update: (proxy, { data: { addMessage, addMessage: { messageAdded } } }) => { /* TODO: error handling when mutation returns error! */

                    if(!addMessage.success) return;

                    socket.emit('new notification', { messageAdded, communityId, roomId });

                    let variables = {
                        communityId
                    }
                    if(roomId && roomId !== 'general') {
                        variables.roomId = roomId;
                    }

                    try {
                        const data = proxy.readQuery({
                            query: GET_MESSAGES,
                            variables
                        });

                        data.messages.messages.push(messageAdded);
                        proxy.writeQuery({ query: GET_MESSAGES, data });

                    } catch(e) {
                        // Do Nothing
                    }
                },
            });
        }

    }));
};

export default withCommunication;