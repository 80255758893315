import React, { Fragment, useState, useEffect } from 'react';
import { useParams, navigate } from "@reach/router";

import { graphql, compose } from 'react-apollo';
import gql from "graphql-tag";

import { Button, Card, Elevation, Toaster, Position, Intent, Icon } from "@blueprintjs/core";

import './index.css';

let toaster = null;

const Invitation = props => {
    const params = useParams();

    const [fase, setFase] = useState('none');

    const [id, setId] = useState(null);
    const [senderName, setSenderName] = useState(null);
    const [communityId, setCommunityId] = useState(null);
    const [communityName, setCommunityName] = useState(null);

    const { token } = params;

    useEffect(() => {
        if (fase === 'none') setFase('verify');
        if (fase === 'verify') {
            setFase('verifying');
            verifyToken();
        }
        if (fase === 'accepting' && props.isLogged) {
            // finalizar e redireciona
            accept();
        }
    }, [fase, props.isLogged]);

    const verifyToken = async () => {
        console.log('Verificando pedido...', token);

        toaster.show({
            message: "Verificando o seu código...",
            icon: "updated",
            timeout: 0,
        }, 'general');

        const { data: { verifyInvitationToken } } = await props.verify({
            variables: { token },
        });

        toaster.dismiss('general');

        if (verifyInvitationToken) {
            const { id, senderName, communityId, communityName } = verifyInvitationToken;
            setId(id);
            setSenderName(senderName);
            setCommunityId(communityId);
            setCommunityName(communityName);
            setFase('verified');
        } else {
            toaster.show({
                message: "Erro! Seu código de convite não pode ser verificado.",
                timeout: 0,
                icon: "warning-sign",
                intent: Intent.DANGER
            });
            setFase('error');
        }
    }

    const doAccept = () => {
        setFase('accepting');
    }

    const accept = async () => {
        console.log('Verificando pedido...', token);

        toaster.show({
            message: "Verificando o seu código...",
            icon: "updated",
            timeout: 0,
        }, 'general');

        const { data: { acceptInvitation: { certificates } } } = await props.accept({
            variables: { token },
            refetchQueries: [{
                query: gql`
                    query getUserCommunities {
                        userCommunities {
                            id
                            name
                            show_bar
                            group
                            tools {
                                id
                                url
                                name
                                component
                            }   
                        }
                    }
                `
            }]
        });

        localStorage.setItem('certificates', JSON.stringify(certificates));

        toaster.dismiss('general');

        // redirect to community
        props.navigate(`/${communityId}`);

    }

    return <div className="tela_invitation">
        <div className="invitation_container">
            <Card interactive={true} elevation={Elevation.TWO}>
                <Icon icon="label" iconSize="100" />
                {fase === 'none' && <div>Carregando dados do convite...</div>}

                {fase === 'verified' && <div>
                    <div>Você recebeu um convite de</div>
                    <Card elevation={Elevation.ONE}><strong>{senderName}</strong></Card>
                    <div>para participar da comunidade</div>
                    <Card elevation={Elevation.ONE}><strong>"{communityName}"</strong></Card>

                    <div><Button text="Participar" onClick={doAccept} fill={true} intent={Intent.PRIMARY} /></div>
                </div>}

                {fase === 'accepting' && <div>
                    {!props.isLogged && <Fragment>
                        <h4 className="warning_message">Você não está logado na plataforma!</h4>
                        <div>
                            <Button intent={Intent.PRIMARY} fill={true} onClick={props.showLogin} text="Acessar" />
                            <Button intent={Intent.WARNING} fill={true} onClick={props.showSignUp} text="Não tenho cadastro" />
                        </div>
                    </Fragment>}

                    {props.isLogged && <Fragment>
                        <div>Aceitando o convite...</div>
                    </Fragment>}
                </div>}

            </Card>
            <Toaster autoFocus={false} canEscapeKeyClear={true} position={Position.TOP} ref={(ref) => (toaster = ref)} />
        </div>
    </div>
};

export default compose(
    graphql(gql`      
        mutation verifyInvitationToken($token: String!) {
            verifyInvitationToken(token: $token) {
                id
                senderName
                communityId
                communityName
            }
        }`,
        { name: 'verify' }
    ),
    graphql(gql`      
        mutation acceptInvitation($token: String!) {
            acceptInvitation(token: $token) {
                certificates
            }
        }`,
        { name: 'accept' }
    ),
)(Invitation);