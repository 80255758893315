import React, { Fragment, useState } from 'react';
import { Query } from "react-apollo";
import gql from 'graphql-tag';

import { withSocket } from '../utils/socketio';

import Community from './community';

import { parsePath } from '../utils';

function CommunityManager(props) {
    // ROUTER RAIZ: recupera comunidade geral
    // OUTRA ROUTER: renderiza comunidade
    const { location } = props;

    const { community: communityId } = parsePath(location.pathname);    

    return <Query query={GET_COMMUNITY} variables={{communityId}}>
        {({ loading, error, data }) => {
            if (loading) return "Carregando...";
            if (error) return `Error! ${error.message}`;

            const { community } = data;

            return (
                <Community {...props} community={community} />
            );
        }}
    </Query>;
}

const GET_COMMUNITY = gql`
  query getCommunity($communityId: ID) {
    community(communityId: $communityId) {
        id
        name
        show_bar
        group
        tools {
            id
            url
            name
            component
        }
    }
  }
`;

export default withSocket(CommunityManager);